import React, { useContext } from "react";
import firebaseApp from "@src/auth/lib/firebaseClient";
import { getAuth } from "firebase/auth";
import { DriverAvatar } from "@src/common/components";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { Items, StatefulMenu } from "baseui/menu";
import { Button, SHAPE } from "baseui/button";
import { AuthContext } from "./AuthProvider";
import { useRouter } from "next/router";

const UserButton: React.FC = () => {
  const { courier: CourierQuery } = useContext(AuthContext);
  const logoUrl = CourierQuery?.courier?.logoUrl;
  const auth = getAuth(firebaseApp);
  const { user } = useContext(AuthContext);
  const router = useRouter();
  const handleSignOut = async () => {
    router.push("/welcome/login");
    await auth.signOut();
  };
  const ITEMS: Items = [{ label: "Settings" }, { label: "Sign Out" }];
  return (
    <StatefulPopover
      focusLock
      placement={PLACEMENT.bottomRight} // Change to bottomRight or topRight
      content={({ close }) => (
        <StatefulMenu
          items={ITEMS}
          onItemSelect={({ item }) => {
            if (item.label === "Sign Out") {
              handleSignOut();
            }
            if (item.label === "Settings") {
              router.push("/settings");
            }
            close();
          }}
          overrides={{
            List: {
              style: { width: "100px" },
            },
          }}
        />
      )}
      overrides={{
        Body: {
          style: {
            zIndex: 100,
          },
        },
      }}
    >
      <div className="flex items-center rounded-md hover:cursor-pointer">
        {logoUrl && <img src={logoUrl} className="h-8 mr-3" alt="Logo" />}
        <Button shape={SHAPE.circle} style={{ height: 30, width: 30 }}>
          {user?.displayName ? (
            <DriverAvatar driverName={user.displayName} />
          ) : null}
        </Button>
      </div>
    </StatefulPopover>
  );
};

export default UserButton;
