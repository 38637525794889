import {
  DriverPayrollSorting,
  DriverSorting,
  InvoiceSorting,
  PricingRulesSorting,
  ShipmentTemplatesSorting,
  ShipmentsSorting,
  EndCustomerSorting,
  AddressBookSorting,
  PayrollDeductionRuleSorting,
} from "@api/graphql/generated/generated-types";
import { isEqual } from "lodash";
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useRef,
} from "react";
import { useLocalStorage } from "usehooks-ts";

export type SortFieldsType =
  | ShipmentsSorting[]
  | PricingRulesSorting[]
  | ShipmentTemplatesSorting[]
  | DriverPayrollSorting[]
  | DriverSorting[]
  | InvoiceSorting[]
  | EndCustomerSorting[]
  | AddressBookSorting[]
  | PayrollDeductionRuleSorting[];
interface ContextState {
  sortFields: SortFieldsType;
  setSortFields: (sortFields: ShipmentsSorting[]) => void;
  setFilters: any;
  filters: any[];
}

export type Filter = {
  id: string | number;
  type: any;
  value: any;
};

const TableControl = createContext<ContextState | undefined>(undefined);

type Props = {
  children: React.ReactNode;
  sortKey: string; // Unique key for the sort fields
};

// Custom hook for deep comparison
const useDeepCompareMemoize = <T,>(value: T) => {
  const ref = useRef<T>();
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

export const TableControlContext = ({ children, sortKey }: Props) => {
  // State using useLocalStorage to persist sortFields
  const [sortFields, setSortFields] = useLocalStorage<ShipmentsSorting[]>(
    `${sortKey}-sortFields`,
    []
  );

  // State for filters
  const [filters, setFilters] = useState<Filter[]>([]);

  // Deep compare memoization for sortFields and filters
  const memoizedSortFields = useDeepCompareMemoize(sortFields);
  const memoizedFilters = useDeepCompareMemoize(filters);

  // Context value memoized to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      sortFields: memoizedSortFields || [],
      setSortFields,
      filters: memoizedFilters || [],
      setFilters,
    }),
    [memoizedSortFields, memoizedFilters] // Updated dependency array
  );

  return (
    <TableControl.Provider value={contextValue}>
      {children}
    </TableControl.Provider>
  );
};

// Custom hook for using the context
export const useTableControl = () => {
  const context = useContext(TableControl);
  if (context === undefined) {
    throw new Error(
      "useTableControl must be used within a TableControlContext"
    );
  }
  return context;
};
