import { ApolloError } from "@apollo/client";
import { ReactNode } from "react";

export interface WidgetState {
  data: any;
  loading: boolean;
  error: ApolloError | null | undefined | object;
}

export enum WidgetName {
  DISPATCH_CANDIDATES = "dispatch-candidates",
  DRIVERS_MAP = "drivers-map",
  ORDER_DETAILS = "order-details",
  ORDERS_PRICE = "orders-price",
  CUSTOMER = "customer",
  USERS = "users",
  COST_CENTERS = "costCenters",
  ORDERS = "orders",
}

export interface DashboardWidget {
  icon: JSX.Element;
  title: string;
  name: WidgetName;
  selected: boolean;
  customComponent: ReactNode | null;
  state: WidgetState;
}
