import React, { ReactNode } from "react";

interface PageContainerProps {
  children: ReactNode;
  widget?: ReactNode;
  isWidgetOpen?: boolean;
}

export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  widget,
  isWidgetOpen = false,
}) => {
  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col bg-white dark:bg-gray-900 rounded-t-lg mr-0.5 shadow-lg">
        {children}
      </div>
      {isWidgetOpen && widget && (
        <div className="w-full md:w-1/4 mt-4 md:mt-0 h-full overflow-auto">
          {widget}
        </div>
      )}
    </div>
  );
};
