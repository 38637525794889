import { Input as BaseInput, InputProps } from "baseui/input";

export interface FractionalInputProps extends InputProps {
  value: number;
  setValue: (value: number) => void;
}

const FractionalInput: React.FC<FractionalInputProps> = ({
  value,
  setValue,
  onChange,
  ...props
}) => {
  return (
    <BaseInput
      value={`${value.toFixed(2)}`}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
        let num: number = 0.0;
        if (event.target.value) {
          const numValue = event.target.value.replace(/[^\d-]|(?!^)-/g, "");
          num = numValue === "" ? 0.0 : Number(numValue);
        }
        setValue(num / 100);
      }}
      {...props}
      overrides={{
        Input: {
          style: {
            backgroundColor: "rgb(249 250 251)",
          },
        },
        ...props.overrides,
      }}
    />
  );
};

export default FractionalInput; 